import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
import "../../src/prism-al-language";
import { Navigation } from "../../src/components/Navigation";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "our-job"
    }}>{`Our Job`}</h1>
    <p>{`So where do we come into play in all of this? The system looks pretty swell and seems
like it has everything you'd need. Well it turns out that there's no one-size-fits-all
when it comes to businesses, and they need the system to be tailored to their needs.
We call this process `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Custom_software"
      }}>{`Customizing`}</a>{`.`}</p>
    <p>{`We typically do these kinds of customizations:`}</p>
    <ul>
      <li parentName="ul">{`Add extra columns or cells (i.e. fields) to existing pages`}</li>
      <li parentName="ul">{`Create new pages with new functionality`}</li>
      <li parentName="ul">{`Perform manual tasks automatically`}</li>
      <li parentName="ul">{`Integrate with other systems (like `}<a parentName="li" {...{
          "href": "https://vipps.no/"
        }}>{`Vipps`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "how-do-i-get-started"
    }}>{`How do I get started?`}</h2>
    <p>{`We create extensions or apps and install those in the customer's environment.
Business Central has its own programming language for this, which is called `}<a parentName="p" {...{
        "href": "https://docs.microsoft.com/en-us/dynamics365/business-central/dev-itpro/developer/devenv-get-started"
      }}>{`AL`}</a>{`.`}</p>
    <p>{`Writing a function for calculating the sum of two numbers in AL, looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-al"
      }}>{`procedure Add(a: Integer; b: Integer): Integer
var
    c: Integer;
begin
    c := a + b;
    exit(c);
end;
`}</code></pre>
    <p>{`Writing a function for calculating the sum of lengths of two text strings, looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-al"
      }}>{`procedure SumOfLength(foo: Text[50]; bar: Text): Integer
var
    sum: Integer;
begin
    // Let's use the function from above
    sum := Add(StrLen(foo), StrLen(bar));
    exit(sum);
end;
`}</code></pre>
    <p>{`That's a lot, take a break and get some fresh air. 🍃`}</p>
    <p>{`When you get back, we'll explore how we go about creating a new extension and deploying it
in your own personal Business Central environment. 🛠️`}</p>
    <Navigation previous="/introduction/business-central" next="/my-first-extension" mdxType="Navigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      